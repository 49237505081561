<template>
  <div>
    <h1>Auth Page</h1>
  </div>
</template>

<script>
export default {
  name: 'Auth',
  layout: 'pages',
  methods: {
    goto () {
      this.$router.push({ name: 'index' })
    }
  }
}
</script>

<style lang="scss" scoped></style>
